<template>
  <div id="navbar">
    <div class="navbar-home">
      <router-link
        class="link font-roboto"
        :to="home.path"
        v-bind:class="{ selected: home.path === activeRoute }"
      >
      <div class="home">{{ $t(`global.navBar.pages[0]`) }}</div>
        <div v-if="activeRoute !== '/'">
          <img src="../../assets/images/house_white.png" />
        </div>
        <div v-else>
          <img src="../../assets/images/house_orange.png" />
        </div>
      </router-link>
    </div>
    <div class="navbar-other">
      <div
        class="navbar-item"
        v-bind:class="{ '': item.path === activeRoute }"
        v-for="(item, index) in routes"
        :key="index"
      >
        <router-link class="font-roboto mx-4 link-router" :to="item.path">
          {{ $t(`global.navBar.pages[${index + 1}]`) }}
        </router-link>
        <div v-bind:class="{ 'yellow-line': item.path === activeRoute }"></div>
      </div>

      <div class="languages flex">
        <div
          class="ro-navbar navbar-item font-roboto cursor-pointer"
          @click="changeLocale('ro')"
          v-bind:class="{ selected: $i18n.locale == 'ro' }"
        >
          RO
        </div>
        <div class="oblique-line mt-4 mr-2"></div>
        <div
          class="en-navbar navbar-item cursor-pointer"
          @click="changeLocale('en')"
          v-bind:class="{ selected: $i18n.locale == 'en' }"
        >
          EN
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      home: {
        path: "/",
      },
      routes: [
        {
          path: "/about",
        },
        {
          path: "/challenges",
        },
        {
          path: "/contact",
        },
        {
          path: "/register",
        },
      ],
      isActiveTheBurger: false,
      activeRoute: this.$route.path,
    };
  },
  watch: {
    $route(to) {
      this.activeRoute = to.path;
    },
  },
  computed: {
    isActiveRoute(route) {
      return {
        "active-link": route === this.activeRoute,
      };
    },
  },
  methods: {
    toggleBurger() {
      this.isActiveTheBurger = !this.isActiveTheBurger;
    },
    deactivate() {
      this.isActiveTheBurger = false;
    },
    changeLocale(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./headers.scss";

img {
  color: white;
  width: 18px;
  height: 18px;
  margin-top: 2px;
  margin-left: 0.3vw;
}

.link {
  display: flex;
}

.languages{
  justify-content: space-between;
  margin-right: 1vw;
}

.yellow-line {
  height: 2px;
  width: 80%;
  background-color: $secondary-color;
  margin: 2px auto 0 auto;
}

.ro-navbar {
  margin-right: .5vw;
}

.en-navbar {
  margin-right: 0;
}

.oblique-line {
  background-color: $secondary-color;
  width: 2px;
  height: 20px;
  margin-top: 2.2vh;
  transform: rotate(20deg);
}

@media screen and (max-width: 800px) {
  .link-router {
    margin: 0 0.5rem;
  }
}

@media screen and (max-width: 730px) {
  .link-router {
    margin: 0 0.3rem;
  }
}

@media screen and (max-width: 700px) {
  .link-router {
    margin: 0 0.1rem;
  }
}
</style>
