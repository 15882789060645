<template>
  <div class="backToTopButton">
    <a href="#" class="button-to-top">
      <div class="img-back"/>
      <div class="img" />
      <img class="transition-image" src="../../assets/images/to-top-button.png">
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    toTopSelector() {
      return document.querySelector(".button-to-top");
    },
  },
  methods: {
    toTopfunction() {
      const toTop = this.toTopSelector;
      const windowOffset = window.scrollY;
      const windowSize = window.innerHeight;
      if (windowOffset + windowSize > 1.6 * windowSize) {
        toTop.classList.add("active");
      } else {
        toTop.classList.remove("active");
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.toTopfunction);
  },
};
</script>

<style lang="scss" scoped>

.backToTopButton {
  z-index: 5;
  position: relative;
  z-index: 999;
}

.img {
  z-index: 1;
  position: relative;
  height: 60%;
  width: 120%;
  clip-path: polygon(50% 0, 100% 100%, 50% 57%, 0% 100%);
  background-color: $card-color;
  transition: 0.3s ease-in;
  right: 25%;
  @media (max-width: 460px) {
    height: 60%;
    width: 120%;
  }
}
.img-back {
  z-index: 0;
  position: relative;
  height: 70%;
  width: 135%;
  clip-path: polygon(50% 0, 100% 100%, 50% 57%, 0% 100%);
  background-color: $secondary-color;
  transition: 0.3s ease-in;
  top: 4%;
  left: 25%;
  @media (max-width: 460px) {
    height: 60%;
    width: 135%;
  }
}

.transition-image {
  z-index: 0;
  display: none;
  background-color: $primary-color;
  height: 45%;
  width: 45%;
}

.button-to-top {
  z-index: 6;
  background-color: $primary-color;
  position: fixed;
  right: 7%;
  bottom: 10vh;
  outline: 1px solid $card-color;
  outline-offset: -4px;
  border: 1.3px solid $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  width: 4.5em;
  height: 4.5em;
  min-width: 32px;
  min-height: 32px;
  pointer-events: none;
  transition: 0.3s;
  @media (max-width: 460px) {
    height: 2.7em;
    width: 2.7em;
  }
}

.button-to-top:hover {
  @media (min-width: 480px) {
    border-radius: 50%;
  }
  & > .img {
    display: none;
  }
  & > .img-back {
    display: none;
  }
  & > .transition-image {
    display: inline;
  }
}

.button-to-top.active {
  pointer-events: all;
  opacity: 1;
}

</style>
