<template>
  <div class="homeHero">
    <div class="main" @click="toChallenges">
        <p class="mainChallengeText">Main Challenge</p>
      <img class="heroImage" src="https://i.imgur.com/yLHIkN5.png">
    </div>
    <div class="background-romb">
      <div
        class="romb"
      >
        <img class="logo" src="https://i.imgur.com/T3E80br.png" />
      </div>
    </div>
    <div class="side"  @click="toChallenges">
      <p class="sideChallengeText">Side Challenge</p>
      <img class="heroImage" src="https://i.imgur.com/HGVqDWI.png">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toChallenges() {
      this.$router.push("/challenges");
    },
  },
};
</script>

<style lang="scss" scoped>
.homeHero {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.side {
  width: 100%;
}

.main {
  width: 100%;
}

.heroImage {
  width: 100%;
  height: 506px;
  object-fit: cover;
}

.background-romb {
  position: absolute;
  align-items: center;
  align-self: center;
  justify-content: center;
  background-color: $secondary-color-line;
  width: 10.2em;
  height: 10.2em;
  display: flex;
  align-items: center;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.romb {
  position: absolute;
  align-items: center;
  align-self: center;
  justify-content: center;
  background-color: $primary-color;
  width: 10em;
  height: 10em;
  display: flex;
  align-items: center;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.logo {
  height: 7em;
  width: 7em;
}

.mainChallengeText {
  position: absolute;
  color: $secondary-color-line;
  font-size: $font_mobile_medium_1;
  text-align: left;
  left: 7%;
  font-weight: bold;
  top: 6em;
  width: 30vw;
  height: 506px;
}

.sideChallengeText {
  position: absolute;
  color: $primary-color-line;
  font-size: $font_mobile_medium_1;
  text-align: left;
  font-weight: bold;
  width: 30vw;
  top: 17.5em;
  left: 65%;
}

 @media (min-width: 840px) {
  .mainChallengeText {
    top: 2em;
    font-size: 48px;
    height: 60px;
    display: block;
    padding: 0.2em;
  }

  .sideChallengeText {
    top: 7em;
    font-size: 48px;
    left: 70%;
  }

  .romb {
    width: 15em;
    height: 15em;;
  }

  .background-romb {
    width: 15.2em;
    height: 15.2em;
  }

  .logo {
    width: 10em;
    height: 10em;
  }
 }
</style>
