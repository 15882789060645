<template>
  <div class="timeline">
    <svg
      width="168"
      height="292"
      viewBox="0 0 168 292"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="svg-top"
    >
      <path d="M166.5 0V104L1 245V291.5" stroke="#1B3256" stroke-width="2" />
    </svg>

    <div class="about" v-for="(image, index) in images" :key="index">
      <div :class="{ mirror: index % 2 == 1 }">
        <div class="vertical-line">
          <div class="text-only">
            <h1
              :class="{ mirror: index % 2 == 1 }"
              class="font-roboto font-bold text-black"
            >
              {{ $t(`About.titles.${index}`) }}
            </h1>
            <div class="title-underline"></div>
            <div class="circle"></div>
            <p :class="{ mirror: index % 2 == 1 }" class="font-roboto">
              {{ $t(`About.desc.${index}`) }}
            </p>
          </div>
        </div>
        <img :src="image.url" />
      </div>
    </div>
    <div
      class="about-desk"
      v-for="(imageDesk, indexDesk) in imagesDesk"
      :key="indexDesk"
    >
      <div>
        <div class="container">
          <div class="text-box">
            <h1
              :class="{ mirrorText: indexDesk % 2 == 1 }"
              class="font-roboto text-2xl font-bold text-blue"
            >
              {{ $t(`About.titles.${indexDesk}`) }}
            </h1>
            <p :class="{ mirrorText: indexDesk % 2 == 1 }" class="font-roboto">
              {{ $t(`About.desc.${indexDesk}`) }}
            </p>
          </div>
          <div class="art-containter" :class="{ mirrorArt: indexDesk % 2 == 1 }">
            <div class="title-underline"></div>
            <div class="circle"></div>
          </div>
          <img
            :src="imageDesk.url"
            :class="{ mirror: indexDesk % 2 == 1, img2: indexDesk == 2 }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        { url: "https://imgur.com/DkPvxFt.png" },
        { url: "https://imgur.com/d2prbnL.png" },
        { url: "https://imgur.com/9fIEEfh.png" },
        { url: "https://imgur.com/d3bFXkY.png" },
      ],
      imagesDesk: [
        { url: "https://imgur.com/zBi5KAk.png" },
        { url: "https://imgur.com/VxOAWSn.png" },
        { url: "https://imgur.com/v6UgEFL.png" },
        { url: "https://imgur.com/9r31DlJ.png" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  background-color: $background-color;
  padding-bottom: 3em;
  margin: 0;
}

.svg-top {
  margin-left: 7vw;
  margin-bottom: -1vh;
}

.title-underline {
  width: 78vw;
  height: 2px;
  background-color: $primary-color;
  margin-left: 7vw;
}

.circle {
  width: 25px;
  height: 25px;
  outline: 2px solid $primary-color;
  border-radius: 50%;
  position: absolute;
  margin-left: 85vw;
  margin-top: -2vh;
}

.vertical-line {
  border-left: 2px solid $primary-color;
  margin-left: 7vw;
}

.text-only {
  margin-left: -7vw;
  padding-top: 2em;
  padding-bottom: 2em;
}

.text-container {
  position: relative;
}

img {
  width: 100%;
  margin-bottom: -0.1vh;
}

.mirror {
  transform: scaleX(-1);
}

h1 {
  font-size: $font_mobile_medium_1;
}
@media only screen and (max-width: 719px) {
  .about-desk {
    display: none;
  }

  p {
    width: 80vw;
    margin-left: 10vw;
    margin-top: 5%;
    margin-bottom: 2vh;
  }
}

@media only screen and (max-width: 330px) {
  h1 {
    font-size: $font_mobile_small_2;
  }
}

@media only screen and (min-width: 720px) {
  .about,
  .svg-top {
    display: none;
  }

  .timeline {
    background-color: $background-color;
    padding-bottom: 3em;
    // margin: 50px; //daca se vrea sau nu patrat
    padding-left: 50px;
    padding-top: 30px;
  }

  .container {
    display: flex;
    align-items: center;
    margin: auto;
    margin-left: 7vw;
  }

  .circle {
    width: 25px;
    height: 25px;
    border: 1px solid $primary-color;
    border-radius: 50%;
    margin-top: -1.5vh;
    margin-left: 10vw;
    position: absolute;
  }

  img {
    width: 20vw;
    margin-left: 15vw;
  }

  .title-underline {
    width: 10vw;
    height: 2px;
    background-color: $primary-color;
    position: relative;
    margin-left: 0vw;
    position: absolute;
  }

  p {
    margin-left: 0;
    width: 30vw;
    position: relative;
  }

  .text-box {
    border-right: 2px solid $primary-color;
    position: relative;
    padding: 5vw;
  }

  h1,
  p {
    text-align: justify;
  }

  .mirror {
    transform: translate(-50vw);
  }

  .mirrorText {
    transform: translate(40vw);
  }
  .mirrorArt {
    transform: scaleX(-1);
  }
  h1 {
    padding-bottom: 2vh;
  }

  .img2 {
    width: 15%;
  }
}
</style>
