<template>
  <div id="burger">
    <div class="switch">
      <div
        :class="{
          'burger-menu': true,
          'burger-menu-open': isTheBurgerActive,
        }"
        @click="toggleBurger()"
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div
      class="burger-active"
      v-if="isTheBurgerActive">
      <div class="burger-end"
        v-on:click="deactivate" :class="{'transparent': false}"
      >
        <div
          class="burger-item"
          v-bind:class="{'selected': item.path === activeRoute}"
          v-for = "(item, index) in routes" :key="index"
        >
          <router-link
            :to="item.path"
          >
            {{ $t(`global.navBar.pages[${index}]`) }}
          </router-link>
        </div>
      </div>
      <div class="languages">
        <div
          class="ro burger-item"
          @click="changeLocale('ro')"
          v-bind:class="{'selected': $i18n.locale == 'ro'}"
        >
          RO
        </div>
        <div
          class="en burger-item"
          @click="changeLocale('en')"
          v-bind:class="{'selected': $i18n.locale == 'en'}"
        >
          EN
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routes: [
        {
          path: "/",
        },
        {
          path: "/about",
        },
        {
          path: "/challenges",
        },
        {
          path: "/contact",
        },
        {
          path: "/register",
        },
      ],
      isTheBurgerActive: false,
      activeRoute: this.$route.path,
    };
  },
  watch: {
    $route(to) {
      this.activeRoute = to.path;
    },
  },
  computed: {
    isActiveRoute(route) {
      return {
        "active-link": route === this.activeRoute,
      };
    },
  },
  methods: {
    toggleBurger() {
      this.isTheBurgerActive = !this.isTheBurgerActive;
    },
    deactivate() {
      this.isTheBurgerActive = false;
    },
    changeLocale(lang) {
      this.$i18n.locale = lang;
      this.deactivate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './../headers.scss';

.burger-menu {
  z-index: 101;
  & > div {
    margin: 6px;
    width: 40px;
    height: 5px;
    right: 0;
    background-color: white;
    border-radius: 2px;
    transition: transform 200ms ease, opacity 200ms ease;
  }
  &-open {
    & > div:first-child {
      transform-origin: top left;
      transform: rotateZ(45deg);
    }
    & > div:nth-child(2) {
      opacity: 0;
    }
    & > div:nth-child(3) {
      transform-origin: center;
      transform: translateY(-9px) translateX(-9px) rotateZ(-227deg);
    }
  }
}

</style>
