<template>
  <div id="about-page">
    <Timeline />
    <Team />
  </div>
</template>

<script>
import Team from "../components/about/Team.vue";
import Timeline from "../components/about/Timeline.vue";

export default {
  components: {
    Team,
    Timeline,
  },
};
</script>
