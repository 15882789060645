<template>
  <div class="rules bg-background_color pb-10">
    <div
      class="rules-content"
      v-for="(rule, index) in $t('register.rules')"
      :key="index"
    >
      <div class="mobile-resolution">
        <div class="flex-container text-left w-4/5 mx-auto mb-5" @click="expandRule(rule, index)">
          <p class="section font-roboto text-blue text-xl font-medium">
            {{ rule.section }}
            <i class="arrow down"></i>
          </p>
          <p class="rule-title font-roboto text-black">
            {{ rule.title }}
          </p>
        </div>
        <transition name="bounce">
          <div
            v-show="rule.expand"
            class="
              rule-container
              -mt-3
              mb-10
              mx-auto
              pt-4
              pb-1
              bg-light_grey
              rounded-sm
            "
          >
            <div
              class="rule-text text-left text-black mb-5 mx-auto"
              v-for="(text, index2) in rule.text"
              :key="index2"
              v-html="text"
            ></div>
          </div>
        </transition>
      </div>
      <div
        class="
          desktop-resolution
          inline-block
          w-4/5
          max-w-2xl
          mx-auto
          text-left
        "
      >
        <div class="title-desktop flex justify-between" @click="expandRule(rule, index)">
          <p class="section font-roboto text-blue text-xl font-medium">
            {{ rule.section }}. {{ rule.title }}
          </p>
          <div class="arrow-desktop down"></div>
        </div>
        <transition name="bounce">
          <div
            v-show="rule.expand"
            class="rule-container mb-10 p-3 bg-light_grey rounded-sm"
          >
            <div
              class="rule-text text-left text-black"
              v-for="(text, index2) in rule.text"
              :key="index2"
              v-html="text"
            ></div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    expandRule(rule, index) {
      rule.expand = !rule.expand; // eslint-disable-line no-param-reassign
      const arrow = document.querySelectorAll(".arrow")[index];
      const arrowDesktop = document.querySelectorAll(".arrow-desktop")[index];
      if (rule.expand) {
        arrow.classList.remove("down");
        arrow.classList.add("up");
        arrowDesktop.classList.remove("down");
        arrowDesktop.classList.add("up");
      } else {
        arrow.classList.remove("up");
        arrow.classList.add("down");
        arrowDesktop.classList.remove("up");
        arrowDesktop.classList.add("down");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/svg/arrow_down.scss";
@import "../../assets/svg/arrow_up.scss";

//BOUNCE TRANSITION TEXT

.bounce-enter-active {
  animation: bounce-in 0.4s;
}
.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

//MOBILE - RESOLUTION

@media screen and (max-width: 480px) {
  .desktop-resolution {
    display: none;
  }

  .arrow {
    position: relative;
    margin-left: 0.5em;
    transition: 0.4s;
  }

  .down {
    top: -4px;
  }

  .up {
    top: 2px;
  }

  .rule-container {
    width: 90%;
  }

  .rule-text {
    width: 88.88%;
  }
}

//DESKTOP - RESOLUTION

@media screen and (min-width: 481px) {
  .mobile-resolution {
    display: none;
  }

  .arrow-desktop {
    transition: 0.3s;
    background-color: $secondary-color;
    height: 3em;
    width: 3em;
  }

  .down {
    transform: rotate(0);
    -webkit-clip-path: polygon(50% 54%, 0 16%, 100% 16%);
    clip-path: polygon(50% 54%, 0 16%, 100% 16%);
  }

  .up {
    transform: rotate(0);
    -webkit-clip-path: polygon(50% 16%, 0 54%, 100% 54%);
    clip-path: polygon(50% 16%, 0 54%, 100% 54%);
  }
}
</style>
