<template>
  <div class="awards pt-10">
    <div class="lines_blue hide-mobile" />
    <div class="lines_orange hide-desktop" />
    <div class="title-flex">
      <img class="homeplageSvg" src="../../assets/svg/homepageSVG.svg" />
      <h1>{{ $t("challenges.title-awards") }}</h1>
    </div>
    <div v-for="challenge in challenges" :key="challenge.name">
      <div v-if="challenge.name === 'main'" class="romb_left" />
      <div
        class="container"
        :class="{
          add_margin_left: challenge.main,
          add_margin_right: !challenge.main,
        }"
      >
        <div v-if="challenge.name === 'main'" class="content-text">
          {{ $t("challenges.main.text-main") }}
        </div>
        <div class="boxes">
          <div class="box-content mt-10">
            <div class="item-1">
              <div v-if="challenge.name == 'main'" class="logo-firme">
                <img src="https://i.imgur.com/JSUT5q9.png" class="mt-7" />
              </div>
            </div>
            <div class="item-2">
              <img
                v-if="challenge.name == 'side'"
                class="logo_firma"
                src="https://i.imgur.com/viNgA1X.png"
              />
            </div>
            <div class="item-3">
              <div v-if="challenge.name == 'main'" class="logo-firme">
                <img src="https://imgur.com/hxUJvvZ.png" />
              </div>
            </div>
            <!-- <div class="item-4"></div> -->
            <div class="item-5">
              <p class="prize pad mt-12">
                {{ $t(`challenges.${challenge.name}.place1.price`) }}
              </p>
            </div>
            <!-- <div class="item-6"></div> -->
          </div>
        </div>
        <div v-if="challenge.name == 'side'" class="content-text">
          {{ $t("challenges.side.text-side") }}
        </div>
      </div>
      <div v-if="challenge.name == 'side'" class="romb_right" />
    </div>
    <div class="lines_blue" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      challenges: [
        {
          name: "main",
          main: true,
        },
        {
          name: "side",
          main: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/svg/romb_right.scss";
@import "../../assets/svg/romb_left.scss";
@import "../../assets/svg/lines_orange.scss";
@import "../../assets/svg/lines_blue.scss";
@import "../../assets/svg/boxes.scss";

.awards {
  position: relative;
}

@media (max-width: 600px) {
  .lines_orange,
  .lines_blue,
  .romb_right,
  .romb_left,
  .boxes {
    margin: 5vh;
  }
  h1 {
    font-size: $font_mobile_medium_2;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    color: $primary-color;
    margin-right: 60%;
  }

  .content-text {
    font-size: $font_mobile_medium_2;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    color: $primary-color;
    margin: auto;
    margin-right: 0%;
    margin-left: 0%;
  }

  .title-flex {
    display: flex;
    margin-left: 10%;
    margin-bottom: 5%;
  }

  .box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $secondary-font-color;
    padding: 5% 15% 15% 15%;
  }

  .item-1 {
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .item-3 {
    margin-bottom: -15px;
    transform: scale(1.1);
  }

  .pad {
    padding-bottom: 40%;
  }

  .prize {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: $font_mobile_small_3;
  }

  .place {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: $font_mobile_small_3;
    margin-bottom: 10%;
  }

  .container {
    display: flex;
  }

  .boxes {
    outline: 2px solid $secondary-color;
  }

  .boxes::before {
    outline: 2px solid $primary-color;
  }

  .romb_left {
    position: absolute;
    top: 26.5%;

    margin-left: -5%;
    height: 20vw;
    width: 20vw;
  }

  .romb_right {
    position: absolute;
    margin-left: 85%;
    top: 65%;
    height: 20vw;
    width: 20vw;
  }

  .add_margin_left {
    margin-left: 30vw;
  }

  .lines_blue {
    margin-top: 15%;
    margin-bottom: 13%;
    margin-left: 30vw;
  }

  .lines_orange {
    margin: 5vh;
    margin-left: 50%;
  }

  .boxes {
    margin: 5vh;
    width: 40%;
    height: fit-content;
  }

  .homeplageSvg {
    height: 6vh;
    width: auto;
    margin-right: 4vw;
  }
  .hide-desktop {
    display: none;
  }
}

@media (min-width: 600px) {
  .lines_orange,
  .lines_blue,
  .romb_right,
  .romb_left,
  .boxes {
    margin: 5vh;
  }

  svg {
    margin-right: 5%;
    width: 50px;
    height: auto;
  }

  h1 {
    font-size: 3.5vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    color: $primary-color;
    margin-right: 60%;
  }

  .content-text {
    font-size: 3vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    color: $primary-color;
    margin: 17vw;
    margin-top: 9.5vw;
    margin-bottom: 0vw;
  }

  .title-flex {
    display: flex;
    margin-left: 10%;
  }

  .box-content {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: 20% 20%;
    grid-gap: 10% 10%;
    place-items: stretch center;
    place-content: stretch center;
    color: $secondary-font-color;
    padding: 10%;
    margin-right: 5%;
  }

  .item-1 {
    grid-area: 1 / 1 / 2 / 2;
  }

  .item-2 {
    grid-area: 1 / 2 / 2 / 3;
  }

  .item-3 {
    grid-area: 1 / 3 / 2 / 4;
    margin-top: 2.5rem;
    img {
      transform: scale(1.5);
    }
  }

  .item-4 {
    grid-area: 2 / 1 / 3 / 2;
  }

  .item-5 {
    grid-area: 2 / 2 / 3 / 3;
  }

  .item-6 {
    grid-area: 2 / 3 / 3 / 4;
  }
  .pad {
    padding-bottom: 40%;
  }

  .prize {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 2.25vw;
  }

  .place {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 1.75vw;
    margin-bottom: 10%;
  }

  .container {
    display: flex;
  }

  .romb_left {
    position: absolute;
    top: 23%;
    margin-left: -3%;
    height: 10vw;
    width: 10vw;
  }

  .romb_right {
    position: absolute;
    margin-left: 92%;
    top: 63%;
    height: 10vw;
    width: 10vw;
  }

  .lines_blue {
    margin-left: -40%;
  }

  .lines_orange {
    margin-left: 80%;
  }

  .add_margin {
    margin-left: 10vw;
  }

  .boxes {
    width: 38vw;
    height: 24vw;
    outline: 3px solid $secondary-color;
    margin-right: 0vw;
  }

  .boxes::before {
    outline: 3px solid $primary-color;
    margin: 2% 0% 0% -53%;
  }

  .homeplageSvg {
    height: 6vw;
    width: auto;
    margin-right: 1vw;
  }

  .logo-firme {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .add_margin_left {
    margin-left: 10vw;
  }

  .add_margin_right {
    margin-left: 10vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
  .content-text {
    font-size: 4vw;
    margin: 14vw;
    margin-top: 10vh;
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 950px) {
  .box-content {
    margin-top: 0;
  }
}

@media (min-width: 600px) {
  .hide-mobile {
    display: none;
  }
}
</style>
