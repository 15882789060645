<template>
    <div class="footer-container">
        <div class="footer">
        <div class="circle">
            <a
            href="https://www.facebook.com/EESTEC.Bucuresti"
            class="footer-icon"
            ><img loading="lazy" src="../../assets/svg/facebook.svg" alt="facebook icon"
            /></a>
        </div>
        <div class="circle">
            <a
            href="https://www.instagram.com/eestec_lc_bucharest"
            class="footer-icon"
            ><img loading="lazy" src="../../assets/svg/instagram.svg" alt="instagram icon"
            /></a>
        </div>
        <div class="circle">
            <a href="https://www.linkedin.com/company/450726/" class="footer-icon"
            ><img loading="lazy" src="../../assets/svg/linkedin.svg" alt="linkedin icon"
            /></a>
        </div>
        <div class="circle">
            <a
            href="https://www.youtube.com/user/eeStecBucuresti"
            class="footer-icon"
            ><img loading="lazy" src="../../assets/svg/youtube.svg" alt="youtubeicon" />
            </a>
        </div>
        </div>
        <div class="footer-info">
        <div class="footer-label fs-small-4 font-montserrat">
            Splaiul Independenței, nr. 313, AN204bis, UPB
        </div>
        <div class="footer-label fs-small-4 font-montserrat">Raluca Loghin ~ {{ $t(`global.footer.contact.title`) }} ~ 0728 074 355</div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      nrOfClicks: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-container {
  background-color: $primary-color;
  width: 100%;
  box-sizing: border-box;
  z-index: 3;
  padding-top: 3%;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
}

.circle {
  margin: 1%;
  @media screen and (max-width: 460px) {
    margin: 1% 2% 3% 2%;
  }
}

.footer-icon img {
  min-width: 30px;
  min-height: 30px;
  width: 3vw;
  height: 3vw;
  max-width: 50px;
  max-height: 50px;
}

.footer-info {
  padding-bottom: 1em;
}

.footer-label {
  width: 100%;
  align-content: center;
  text-align: center;
  display: flex;
  justify-content: center;
  line-height: 2em;
  font-family: "PT Sans Caption";
  color: white;
  font-size: $font_mobile_small_2;
  @media screen and (max-width: 460px) {
    font-size: $font_mobile_small_1;
  }
}

@media screen and (min-width: $min_desktop_width) {
  .footer-icon img {
    width: 4vw;
    height: 4vw;
  }

  .main-img {
    object-fit: cover;
    width: 100%;
    margin-bottom: -5px;
  }

  .circle {
    background-color: $primary-color;
    border-radius: 50%;
    line-height: 0;
    z-index: 5;
  }
}
</style>
