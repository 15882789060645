<template>
  <div class="registerHero">
    <div class="apply">
      <img class="left" src="../../assets/svg/register_top.svg" />
      <a class="romb1" href="https://forms.gle/f6YVykGY38uQ9y1r7">
        <div class="text1 text-xl font-bold font-roboto text-black">
          {{ $t("register.apply") }}
        </div>
      </a>
    </div>
    <img class="logo" src="../../assets/images/logo_eestec_olympics_10.png" />
    <div class="rule">
      <img class="right" src="../../assets/svg/register_bottom.svg" />
      <div class="romb2">
        <div class="text2 text-xl font-roboto font-bold text-black">
          {{ $t("register.rule") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.registerHero {
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  padding-bottom: 5em;
}

.left {
  position: absolute;
  margin-left: 1vw;
  margin-top: 4vh;
  width: 80%;
}

.right {
  position: absolute;
  margin-left: 15vw;
  margin-top: -32vh;
  width: 80%;
}

.apply,
.rule {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.romb1 {
  border: 4px solid #f0ad30;
  background-color: #d7dedc;
  transform: rotate(45deg);
  width: 6em;
  height: 6em;
  position: absolute;
  margin-left: 58vw;
  margin-top: 7vh;
}

.romb1:hover {
  .text1 {
    color: #f0ad30;
  }
}

.text1 {
  transform: rotate(-45deg);
  position: relative;
  align-self: center;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}

.romb2 {
  border: 4px solid #f0ad30;
  background-color: #d7dedc;
  transform: rotate(45deg);
  width: 8em;
  height: 8em;
  position: absolute;
  margin-left: 15vw;
  margin-top: -20vh;
}

.text2 {
  transform: rotate(-45deg);
  position: relative;
  align-self: center;
  margin-bottom: 2.4em;
  margin-top: 2.4em;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding-top: 16em;
  padding-bottom: 16em;
}

@media screen and(max-width: 321px) {
  .romb1 {
    width: 5em;
    height: 5em;
    margin-top: 8vh;
  }

  .romb2 {
    border: 4px solid #f0ad30;
    background-color: #d7dedc;
    transform: rotate(45deg);
    width: 6em;
    height: 6em;
    position: absolute;
    margin-left: 15vw;
    margin-top: -20vh;
  }

  .text1 {
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 1.6em;
    margin-top: 1.6em;
    margin-left: 0.4em;
    margin-right: 0.4em;
  }

  .text2 {
    font-size: 1rem;
    line-height: 0rem;
    margin-bottom: 3em;
    margin-top: 3em;
    margin-left: -0.4em;
    margin-right: 0.4em;
  }

  .logo {
    padding-top: 12em;
    padding-bottom: 12em;
  }
}

@media screen and(min-width: 480px) {
  .romb1 {
    width: 12vw;
    height: 12vw;
    margin-top: 10vw;
    margin-left: 16vw;
  }

  .romb2 {
    width: 14vw;
    height: 14vw;
    margin-left: 63vw;
    margin-top: -21vw;
  }

  .left {
    position: absolute;
    margin-left: 3vw;
    margin-top: 2vw;
    width: 33.5%;
  }

  .right {
    position: absolute;
    margin-left: 63vw;
    margin-top: -30vw;
    width: 33.5%;
  }

  .text1 {
    font-size: 2.5vw;
    line-height: 1vw;
    font-weight: 400;
    margin-bottom: 6vw;
    margin-top: 5.2vw;
    margin-left: 1.6vw;
    margin-right: 1.6vw;
  }

  .text2 {
    font-size: 2.5vw;
    line-height: 1vw;
    font-weight: 400;
    margin-top: 7vw;
    margin-left: -0.7vw;
  }

  .logo {
    width: 20%;
    padding-top: 13vw;
    padding-bottom: 16vw;
  }
}
</style>
