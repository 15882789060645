<template>
  <div class="testimonials">
    <div class="background"></div>
    <div class="title pt-5 mb-5">
      <h1 class="font-roboto text-2xl font-bold text-black">
        {{ $t("home.Testimonials.testimonials") }}
      </h1>
      <img class="bullet" src="../../assets/svg/homepageSVG.svg" />
    </div>

    <div class="romb-container">
      <div
        class="romb top"
        @click="zoomIn(0)"
        :class="{ zoom0: rombs[0].zoomActive, hide: rombs[0].hideActive }"
      >
        <div v-if="!rombs[0].zoomActive">
          <p class="romb-text-small">
            {{ $t("testimoniale.testimonialsNames[0]") }}
          </p>
        </div>
        <div v-else>
          <p class="romb-text">{{ $t("testimoniale.testimonialsDesc[0]") }}</p>
          <p class="back-button">{{ $t("testimoniale.butonTestimonial") }}</p>
        </div>
      </div>
      <div class="inner-romb-container">
        <div
          class="romb left"
          @click="zoomIn(1)"
          :class="{ zoom1: rombs[1].zoomActive, hide: rombs[1].hideActive }"
        >
          <div v-if="!rombs[1].zoomActive">
            <p class="romb-text-small">
              {{ $t("testimoniale.testimonialsNames[1]") }}
            </p>
          </div>
          <div v-else>
            <p class="romb-text">
              {{ $t("testimoniale.testimonialsDesc[1]") }}
            </p>
            <p class="back-button">{{ $t("testimoniale.butonTestimonial") }}</p>
          </div>
        </div>
        <div
          class="romb"
          :class="{ zoom2: rombs[2].zoomActive, hide: rombs[2].hideActive }"
        >
          <img class="logo" src="https://imgur.com/Lh6Dxnh.png" />
        </div>
        <div
          class="romb right"
          @click="zoomIn(3)"
          :class="{ zoom3: rombs[3].zoomActive, hide: rombs[3].hideActive }"
        >
          <div v-if="!rombs[3].zoomActive">
            <p class="romb-text-small">
              {{ $t("testimoniale.testimonialsNames[2]") }}
            </p>
          </div>
          <div v-else>
            <p class="romb-text">
              {{ $t("testimoniale.testimonialsDesc[2]") }}
            </p>
            <p class="back-button">{{ $t("testimoniale.butonTestimonial") }}</p>
          </div>
        </div>
      </div>
      <div
        class="romb bottom"
        @click="zoomIn(4)"
        :class="{ zoom4: rombs[4].zoomActive, hide: rombs[4].hideActive }"
      >
        <div v-if="!rombs[4].zoomActive">
          <p class="romb-text-small">
            {{ $t("testimoniale.testimonialsNames[3]") }}
          </p>
        </div>
        <div v-else>
          <p class="romb-text">{{ $t("testimoniale.testimonialsDesc[3]") }}</p>
          <p class="back-button">{{ $t("testimoniale.butonTestimonial") }}</p>
        </div>
      </div>
    </div>
    <div class="block-container">
      <div class="card-container" v-for="(romb, index) in 4" :key="index">
        <div :class="{ mirror: index % 2 == 1 }" class="card">
          <p class="text-desk" :class="{ mirror: index % 2 == 1 }">
            {{ $t(`testimoniale.testimonialDescDesk.${index}`) }}
          </p>
          <p class="nume-desk" :class="{ mirrorText: index % 2 == 1 }">
            {{ $t(`testimoniale.numeDesk.${index}`) }}
          </p>
        </div>
      </div>
      <div class="logo-desktop">
        <img
          style="transform: rotate(-45deg)"
          src="https://imgur.com/Lh6Dxnh.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rombs: [
        {
          index: 0,
          zoomActive: false,
          hideActive: false,
        },
        {
          index: 1,
          zoomActive: false,
          hideActive: false,
        },
        {
          index: 2,
          zoomActive: false,
          hideActive: false,
        },
        {
          index: 3,
          zoomActive: false,
          hideActive: false,
        },
        {
          index: 4,
          zoomActive: false,
          hideActive: false,
        },
      ],
    };
  },

  methods: {
    zoomIn(nr) {
      this.rombs[nr].zoomActive = !this.rombs[nr].zoomActive;
      this.rombs.forEach((romb) => {
        if (nr === romb.index) {
          /*eslint operator-linebreak: [2, "after", { "overrides": { "?": "ignore", ":": "ignore"} }]*/
          this.rombs[nr].hideActive = false;
        } else {
          this.rombs[romb.index].hideActive =
            !this.rombs[romb.index].hideActive;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.testimonials {
  background-color: $background-color;
  position: relative;
  margin-top: -2vh;
}

.background {
  width: 100%;
  height: 100%;
  background-color: $secondary-color-line;
  opacity: 50%;
  clip-path: polygon(50% 0%, 100% 0%, 50% 100%, 0% 100%);
  position: absolute;
  z-index: 0;
}

.romb-container {
  width: 380px;
  height: 380px;
  transform: rotate(-45deg);
  margin: auto;
}

.bullet {
  position: absolute;
  width: 3rem;
  margin-left: 1rem;
}
h1 {
  position: relative;
  z-index: 99;
  margin-left: 3em;
  // font-size: 2.6vw;
}
.title {
  display: flex;
  flex-direction: row;
  margin-top: 2vh;
}
.inner-romb-container {
  display: flex;
  width: fit-content;
  margin-left: 0.7em;
}
.romb {
  background-color: $primary-color;
  width: 7em;
  height: 7em;
  margin: auto;
  display: flex;
  align-items: center;
  transition: 0.1s linear;
}
.top {
  margin-bottom: 0.7em;
}

.bottom {
  margin-top: 0.7em;
}

.left {
  margin-right: 0.7em;
}

.right {
  margin-left: 0.7em;
}

.logo {
  transform: rotate(45deg);
}

.zoom0 {
  width: 250px;
  height: 250px;
  transform: rotate(45deg) translate(18%, 16%);
  align-self: center;
}

.zoom1 {
  width: 250px;
  height: 250px;
  transform: rotate(45deg) translate(0%, -30%);
}

.zoom3 {
  width: 250px;
  height: 250px;
  transform: rotate(45deg) translate(-70%, 40%);
}

.zoom4 {
  width: 250px;
  height: 250px;
  transform: rotate(45deg) translate(-50%, -50%);
}

.hide {
  visibility: hidden;
}

.romb-text-small {
  color: $card-color;
  transform: rotate(45deg);
}

.romb-text {
  color: $card-color;
  overflow: scroll;
  padding: 1em;
  height: 200px;
}
.back-button {
  color: $secondary-color;
  margin-top: 0.5em;
}

@media only screen and (min-width: 500px) {
  .bullet {
    position: absolute;
    margin-top: 3vw;
    width: 7vw;
  }

  h1 {
    margin-left: 12vw;
    font-size: 2.6vw;
    margin-top: 3vw;
  }
}

@media (min-width: 900px) {
  h1 {
    margin-top: 4.5vw;
    margin-left: 11vw;
  }
}

@media only screen and (max-width: 719px) {
  .logo-desktop,
  .text-desk,
  .nume-desk {
    display: none;
  }
}

@media only screen and (min-width: 720px) {
  .romb,
  .romb-container {
    display: none;
  }

  .card {
    background-color: $primary-color;
    width: 600px;
    height: 300px;
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 51%, 0% 0%);
    margin-left: 1px;
    margin-bottom: 15px;
  }
  .card-container {
    display: inline-flex;
    position: relative;
  }
  .block-container {
    max-width: 1700px;
    height: 800px;
    width: 1300px;
    display: block;
    margin: auto;
  }

  .mirror {
    transform: scaleX(-1);
  }

  .title {
    padding-bottom: 5vh;
    font-size: 2.6vw;
  }
  .logo-desktop {
    background-color: $primary-color;
    width: 7em;
    height: 7em;
    position: absolute;
    transform: rotate(45deg) scale(1.8);
    margin-top: -380px;
    margin-left: 595px;
  }

  .text-desk {
    font-size: $font_mobile_small_2;
    color: white;
    width: 60%;
    margin-left: 10%;
    margin-top: 5%;
    margin-bottom: 3%;
    height: 70%;
    overflow-y: scroll;
  }
  .text-desk::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .nume-desk {
    text-align: left;
    color: white;
    margin-left: 5%;
    margin-top: 0%;
  }

  .mirrorText {
    transform: scaleX(-1);
    width: 40%;
  }

  @media only screen and (max-width: 1305px) {
    .card {
      width: 500px;
      height: 250px;
    }
    .block-container {
      height: 600px;
      width: 1100px;
      margin: auto;
    }

    .logo-desktop {
      width: 6em;
      height: 6em;
      margin-top: -320px;
      margin-left: 502px;
    }

    .text-desk {
      height: 60%;
    }

    .nume-desk {
      margin-top: 3%;
    }
  }

  @media only screen and (max-width: 1090px) {
    .card {
      width: 450px;
      height: 225px;
    }
    .block-container {
      height: 600px;
      width: 1000px;
      margin: auto;
    }

    .logo-desktop {
      width: 5.5em;
      height: 5.5em;
      margin-top: -292px;
      margin-left: 457px;
    }

    @media only screen and (max-width: 990px) {
      .card {
        width: 400px;
        height: 200px;
      }
      .block-container {
        height: 500px;
        width: 900px;
        margin: auto;
      }

      .logo-desktop {
        width: 5em;
        height: 5em;
        margin-top: -263px;
        margin-left: 410px;
      }
    }
  }
  @media only screen and (max-width: 880px) {
    .card {
      width: 350px;
      height: 175px;
    }
    .block-container {
      height: 450px;
      width: 800px;
      margin: auto;
    }

    .logo-desktop {
      width: 4.2em;
      height: 4.2em;
      margin-top: -230px;
      margin-left: 368px;
    }
  }
  @media only screen and (max-width: 790px) {
    .card {
      width: 325px;
      height: 162.5px;
    }
    .block-container {
      height: 450px;
      width: 750px;
      margin: auto;
    }

    .logo-desktop {
      width: 4em;
      height: 4em;
      margin-top: -215px;
      margin-left: 343px;
    }
    .mirrorText {
      width: 30%;
    }
  }
}
</style>
