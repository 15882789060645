<template>
    <div id="home-page">
        <HomeHero />
        <Countdown />
        <Partners />
        <Testimonials />
    </div>
</template>

<script>
import Countdown from "../components/home/Countdown.vue";
import HomeHero from "../components/home/HomeHero.vue";
import Partners from "../components/home/Partners.vue";
import Testimonials from "../components/home/Testimonials.vue";

export default {
  components: {
    Countdown,
    HomeHero,
    Partners,
    Testimonials,
  },
};
</script>
