<template>
  <div class="challenges mb-10 pt-5">
    <div class="lines_orange" />
    <div
      v-for="(event, index) in $t('challenges.challenges-info')"
      :key="index"
      class="wrapper"
    >
      <div class="title-container">
        <img
          class="title-content title-image"
          src="../../assets/svg/homepageSVG.svg"
        />
        <h1 class="font-montserrat title title-content">{{ event.title }}</h1>
      </div>
      <div>
        <div v-if="!mobile" class="text-wrapper">
          <p
            class="challenge-text center"
            :class="{ 'main-less': event.main, 'side-less': !event.main }"
          >
            {{ event.shortdescription }}
          </p>
          <button
            class="see-more-button"
            :class="{ 'main-less': event.main, 'side-less': !event.main }"
            v-on:click="displayDetails(event.name)"
          >
            {{ $t("challenges.button-more") }}
          </button>
        </div>
        <div
          class="challenge-image-container center"
          :class="{ 'main-less': event.main, 'side-less': !event.main }"
        >
          <img
            class="center"
            v-if="event.main"
            src="https://i.imgur.com/ksq7ViZ.jpg"
          />
          <img
            class="center"
            v-if="!event.main"
            src="https://i.imgur.com/wAQtRXi.jpg"
          />
          <div class="image-outline"></div>
        </div>
      </div>
      <p
        class="challenge-text center"
        :class="{ 'main-less': event.main, 'side-less': !event.main }"
        v-if="mobile"
      >
        {{ event.shortdescription }}
      </p>
      <button
        class="see-more-button"
        :class="{ 'main-less': event.main, 'side-less': !event.main }"
        v-on:click="displayDetails(event.name)"
        v-if="mobile"
      >
        {{ $t("challenges.button-more") }}
      </button>
      <div
        class="challenge-text-container center more"
        :class="{ 'main-more': event.main, 'side-more': !event.main }"
      >
        <p
          class="challenge-text challenge-text-more"
          :class="{ 'main-more': event.main, 'side-more': !event.main }"
        >
          {{ event.longdescription }}
        </p>
        <div
          class="text-outline"
          :class="{ 'main-more': event.main, 'side-more': !event.main }"
        ></div>
      </div>
      <button
        class="see-more-button more"
        :class="{ 'main-more': event.main, 'side-more': !event.main }"
        v-on:click="displayLessDetails(event.name)"
      >
        {{ $t("challenges.button-less") }}
      </button>

      <div :class="{ lines_blue: event.main }" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Challenges",
  data() {
    return {
      mobile: window.innerWidth < 1025,
    };
  },
  methods: {
    displayDetails(type) {
      console.log(type);
      const classNameLess = `${type}-less`;
      const docsLess = document.getElementsByClassName(classNameLess);
      docsLess[0].style.display = "none";
      docsLess[1].style.display = "none";
      docsLess[2].style.display = "none";
      const classNameMore = `${type}-more`;
      const docsMore = document.getElementsByClassName(classNameMore);
      docsMore[0].style.display = "inline-block";
      docsMore[1].style.display = "inline-block";
      docsMore[2].style.display = "inline-block";
      docsMore[3].style.display = "inline-block";
    },
    displayLessDetails(type) {
      console.log(type);
      const classNameMore = `${type}-more`;
      const docsMore = document.getElementsByClassName(classNameMore);
      docsMore[0].style.display = "none";
      docsMore[1].style.display = "none";
      docsMore[2].style.display = "none";
      docsMore[3].style.display = "none";
      const classNameLess = `${type}-less`;
      const docsLess = document.getElementsByClassName(classNameLess);
      docsLess[0].style.display = "inline-block";
      docsLess[1].style.display = "inline-block";
      docsLess[2].style.display = "inline-block";
    },
    myEventHandler() {
      this.mobile = window.innerWidth < 1025;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/svg/lines_blue.scss";
@import "../../assets/svg/lines_orange.scss";

.challenges {
  margin-bottom: 5vh;
  position: relative;
}

.title-container {
  display: block;
  text-align: left;
  width: 80%;
  margin-left: 13%;
  margin-top: 5%;
  margin-bottom: 3rem;
}

.title-content {
  display: inline-block;
}

.title-image {
  margin-right: 10px;
  width: 50px;
  height: auto;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #1b3256;
  vertical-align: middle;
}

.challenge-image-container {
  width: 75%;
  position: relative;
}

.center {
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.image-outline {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 3px solid #f0ad30;
  top: -10px;
  left: 10px;
  z-index: 10;
}

.challenge-text {
  margin-top: 3vh;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #1b3256;
  width: 70%;
  text-align: left;
}

.see-more-button {
  background: #eeab3f !important;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  margin-top: 2vh;
  margin-right: calc(85% - 210px);
  margin-left: 15%;
  width: 210px;
  color: #1b3256 !important;
}

.more {
  display: none;
  background: #1b3256;
  color: #ffffff;
}

.challenge-text-more {
  margin-top: 0vh;
  color: #ffffff;
  padding: 10%;
  text-align: center;
  width: 100%;
}

.challenge-text-container {
  width: 70%;
  position: relative;
}

.text-outline {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 3px solid #f0ad30;
  top: -10px;
  left: 10px;
  z-index: 10;
}

.see-more-button:hover {
  opacity: 0.8;
}

.lines_blue,
.lines_orange {
  margin-left: 50%;
  right: -15vw;
}

.lines_blue {
  margin-top: 10%;
}

@media (min-width: 1025px) {
  .title {
    font-size: 3.5vw;
  }

  .title-image {
    width: 6vw;
  }

  .title-container {
    margin-left: 9%;
  }

  .wrapper {
    display: block;
  }

  .challenge-text {
    font-size: 30px;
    line-height: 40px;
  }

  .see-more-button {
    font-size: 25px;
    margin-top: 7vh;
  }

  .challenge-image-container {
    display: inline-block;
    width: 35%;
    vertical-align: middle;
  }

  .image-outline {
    top: -30px;
    left: 30px;
  }

  .text-wrapper {
    display: inline-block;
    width: 40%;
    vertical-align: middle;
    margin-right: 15%;
  }

  .lines_orange,
  .lines_blue {
    margin-left: 60%;
    width: 40em;
  }
}
</style>
