<template>
    <div id="challenges-page">
        <Challenges />
        <Awards />
        <Timeline />
    </div>
</template>

<script>
import Awards from "../components/challenges/Awards.vue";
import Challenges from "../components/challenges/Challenges.vue";
import Timeline from "../components/challenges/Timeline.vue";

export default {
  components: {
    Awards,
    Challenges,
    Timeline,
  },
};
</script>

<style lang="scss" scoped>

#challenges-page {
  background-color: $background-color;
}
</style>
