<template>
  <div id="teamLineup" class="-mb-5">
    <h1 class="team-lineup-title">{{ $t("team.title") }}</h1>
    <div id="container">
      <div
        v-for="(member, index) in team"
        :key="index"
        :style="member.style"
        class="member"
      >
        <div class="above-container">
          <div class="member-name">
            <h1 v-html="member.name"></h1>
            <h1 class="is-hidden-desktop">
              {{ $t(member.position).toUpperCase() }}
            </h1>
          </div>
        </div>
        <div class="member-image-container">
          <img :style="member.imgStyle" class="image" :src="member.imgSrc" />
        </div>
        <div class="bellow-container">
          <div class="member-title">
            <h1 class="desktop-title">
              {{ $t(member.position).toUpperCase() }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      team: [
        {
          name: 'EMILIA<br class="is-hidden-mobile"> COCORU',
          position: "team.tehnic",
          /* eslint-disable global-require */
          imgSrc: "https://i.imgur.com/D7NIzCL.png",
          style: "",
          imgStyle: "",
          imgProps: {
            widthModifier: 0.8,
            offsetValue: 6,
            mobile: {
              widthModifier: 90,
            },
          },
        },
        {
          name: 'AURELIA<br class="is-hidden-mobile"> VOICAN',
          position: "team.fr",
          imgSrc: "https://i.imgur.com/KyveBHA.png",
          style: "",
          imgStyle: "",
          imgProps: {
            widthModifier: 0.78,
            offsetValue: 6,
            mobile: {
              widthModifier: 90,
              offsetValue: 0,
            },
          },
        },
        {
          name: 'ADELINA<br class="is-hidden-mobile"> RĂDUCAN',
          position: "team.logistic",
          imgSrc: "https://i.imgur.com/Eaq9Sda.png",
          style: "",
          imgStyle: "",
          imgProps: {
            widthModifier: 0.79,
            offsetValue: 0.1,
            mobile: {
              widthModifier: 90,
              offsetValue: 20,
            },
          },
        },
        {
          name: 'ROBERT<br class="is-hidden-mobile"> IACOB',
          position: "team.stand-fun",
          imgSrc: "https://i.imgur.com/6U9vr22.png",
          style: "",
          imgStyle: "",
          imgProps: {
            widthModifier: 0.8,
            offsetValue: 6,
            mobile: {
              widthModifier: 90,
              offsetValue: 0,
            },
          },
        },
        {
          name: 'RĂZVAN<br class="is-hidden-mobile"> MATIȘAN',
          position: "team.coordonator",
          imgSrc: "https://i.imgur.com/jzBKVa4.png",
          style: "",
          imgStyle: "",
          imgProps: {
            widthModifier: 1,
            offsetValue: 0.1,
            mobile: {
              widthModifier: 90,
              offsetValue: 0,
            },
          },
        },
        {
          name: 'DENIS<br class="is-hidden-mobile"> CHIVU',
          position: "team.pr",
          imgSrc: "https://i.imgur.com/nWIfDQp.png",
          style: "",
          imgStyle: "",
          imgProps: {
            widthModifier: 0.93,
            offsetValue: 0,
            mobile: {
              widthModifier: 90,
              offsetValue: 0,
            },
          },
        },
        {
          name: 'NICOLETA<br class="is-hidden-mobile"> CORNEANU',
          position: "team.academic",
          imgSrc: "https://i.imgur.com/Xqe7Igc.png",
          style: "",
          imgStyle: "",
          imgProps: {
            widthModifier: 0.81,
            offsetValue: 4,
            mobile: {
              widthModifier: 90,
              offsetValue: 0,
            },
          },
        },
        {
          name: 'ALEXANDRA<br class="is-hidden-mobile"> UDREA',
          position: "team.hr",
          imgSrc: "https://i.imgur.com/TJhZGYT.png",
          style: "",
          imgStyle: "",
          imgProps: {
            widthModifier: 0.86,
            offsetValue: 4,
            mobile: {
              widthModifier: 90,
              offsetValue: 0,
            },
          },
        },
      ],
    };
  },
  created() {
    const zIndexBase = 500;
    const baseWidth = 17;
    const middle = this.team.length / 2 - 0.5;

    const mobile = window.innerWidth < 600;
    if (mobile) {
      const aux = this.team[0];
      // eslint-disable-next-line
      this.team[0] = this.team[4];
      this.team[4] = aux;
    }
    // eslint-disable-next-line
    for (const member of this.team) {
      const i = this.team.indexOf(member);

      let widthStyle = `width: ${baseWidth * member.imgProps.widthModifier}vw;`;
      const marginStyleValue = `-${member.imgProps.offsetValue}vw;`;

      if (mobile) {
        widthStyle = `width: ${member.imgProps.mobile.widthModifier}vw;`;
        member.style = `margin-left: ${
          (100 - member.imgProps.mobile.widthModifier) / 2
        }vw; z-index: 500`;
        member.imgStyle = widthStyle;
      } else if (i < middle) {
        member.style = `margin-right:${marginStyleValue};z-index: ${
          zIndexBase + i
        };`;
        member.imgStyle = widthStyle;
      } else if (i > middle) {
        member.style = `margin-left:${marginStyleValue};z-index: ${
          zIndexBase + this.team.length - i - 1
        };`;
        member.imgStyle = widthStyle;
      } else {
        member.style = `z-index: ${zIndexBase + middle};`;
        member.imgStyle = widthStyle;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#teamLineup {
  background-color: $primary-color;
  padding: 100px 0 90px 0;
}

.member {
  display: inline-block;
  position: relative;
  height: 100%;
  vertical-align: bottom;
  transition: all 0.2s;
  transform-origin: center bottom;
  margin: 0 auto;
}
.member-image-container {
  position: relative;
}
.is-hidden-desktop {
  display: None;
}
h1 {
  font-size: 1vw;
  color: #fff;
  z-index: 550;
}
#container {
  width: 95vw;
  margin: 0 auto;
}

.bellow-container {
  position: relative;
  width: 100%;
  bottom: 0;
}
.above-container {
  position: relative;
  width: 100%;
}
.member-name,
.member-title {
  width: 10vw;
  margin: 0 auto;
}

.member-name {
  width: 100%;
}

.team-lineup-title {
  font-size: 32px;
  position: relative;
  top: -90px;
}

.member-title {
  width: 10vw;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 600px) {
  #container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .member-name > h1 {
    font-size: 12px;
  }

  .is-hidden-mobile {
    display: None;
  }
  .member {
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10%;
  }
  .member-name {
    padding-top: 10%;
  }
  .member-image-container {
    margin-bottom: 7%;
  }
  .member-title {
    transform: scale(3);
    z-index: 100;
  }
  .bellow-container {
    width: 100%;
    bottom: 0;
  }
  .above-container {
    width: 100%;
  }
}

@media screen and (min-width: 601px) {
  .member:hover {
    transform: scale(1.15);
    transform-origin: center bottom;
  }
  .member:nth-child(1) {
    margin-bottom: -1.2%;
  }
  .member:nth-child(2) {
    margin-bottom: -1.2%;
    margin-left: -0.5%;
  }
  .member:nth-child(3) {
    margin-bottom: -1.2%;
    margin-left: 0;
  }
  .member:nth-child(4) {
    margin-bottom: -2.8%;
    margin-left: -7%;
    padding-right: 6%;
  }
  .member:nth-child(5) {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 0.3%;
  }
  .member:nth-child(6) {
    margin-bottom: -1.5%;
    margin-right: -1%;
  }
  .member:nth-child(7) {
    margin-bottom: -1.5%;
    margin-right: 0;
  }
  .member:nth-child(8) {
    margin-bottom: -1.5%;
  }
  .member {
    display: inline-block;
    position: relative;
    height: 100%;
    vertical-align: bottom;
    transition: all 0.2s;
    transform-origin: center bottom;
    margin: 0 -2% 0 -2%;
  }
  .member-title h1 {
    transform: scale(0.8);
    // font-size: 0.8vw;
  }

  // .desktop-title{
  //   font-size: 0.8vw;
  // }
}
</style>
