<template>
  <div class="contactPersons pt-5">
    <div class="lines_orange" />
    <h1
      class="title-mobile font-roboto text-black"
      v-html="$t('contact.contactPerson.title-mobile') + '<br>'"
    ></h1>
    <h2
      class="title-desktop font-roboto text-black"
      v-html="$t('contact.contactPerson.title-desktop')"
    ></h2>
    <div class="background">
      <div class="card" v-for="(contact, index) in contacts" :key="index">
        <div class="person">
          <img loading="lazy" :src="contact.photo" />
          <div class="boxes" />
        </div>
        <div class="text-box">
          <h2 class="big-text font-roboto text-xl text-black">
            {{ $t(`contact.contactPerson.contacts[${index}].title`) }}
          </h2>
          <p class="big-text font-roboto text-xl text-black">
            {{ $t(`contact.contactPerson.names[${index}].name`) }}
          </p>
          <div class="small-text text-sm font-roboto text-black">
            {{ $t(`contact.contactPerson.phones[${index}].phone`) }}
          </div>
          <div class="small-text text-sm font-roboto text-black">
            {{ $t(`contact.contactPerson.emails[${index}].email`) }}
          </div>
        </div>
        <div class="romb_left mobile" />
        <div class="romb_right mobile" />
      </div>
      <div class="romb_left desktop" />
      <div class="romb_right desktop" />
    </div>
  </div>
</template>

<script>
import contacts from "../../assets/resources/contacts";

export default {
  data() {
    return {
      contacts,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/svg/romb_left.scss";
@import "../../assets/svg/romb_right.scss";
@import "../../assets/svg/lines_orange.scss";
@import "../../assets/svg/boxes.scss";

.contactPersons {
  background-color: $background-color;
}

.title-desktop {
  font-weight: 600;
  font-size: 2.5vw;
  text-align: left;
  margin-left: 6vw;
  margin-bottom: -2vw;
  @media (max-width: 480px) {
    display: none;
  }
}

.desktop {
  @media (max-width: 840px) {
    display: none;
  }
}

.mobile {
  @media (min-width: 840px) {
    display: none;
  }
}

.title-mobile {
  font-weight: bold;
  font-size: 1.5rem;
  text-align: left;
  margin-left: 5vh;
  @media (min-width: 480px) {
    display: none;
  }
}

.lines_orange {
  margin-left: 20vh;
  margin-bottom: 1vh;
}

.person {
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  width: 14.5em;
  height: 14.5em;
  clip-path: polygon(51% 0, 100% 50%, 50% 100%, 0% 50%);
  object-fit: cover;
}

.boxes {
  transform: rotate(45deg);
  width: 12em;
  height: 12em;
  position: absolute;
}

.boxes:before {
  margin: 3% 0% 0% -54%;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em 0;
  width: 100%;
  position: relative;
}

.text-box {
  margin-top: 3rem;
}

.romb_right {
  position: absolute;
  margin-left: 95%;
  top: 70%;
}

.romb_left {
  position: absolute;
  top: 70%;
  margin-right: 95%;
  margin-left: 0%;
}

@media screen and(min-width: 480px) and (max-width: 840px) {
  .lines_orange {
    width: 25em;
    margin-left: 50vw;
    margin-bottom: 0vh;
  }

  .lines_orange::before {
    margin: 4% 0% 0% -20%;
  }

  .big-text {
    font-size: 4vw;
    margin-bottom: 0.8em;
  }

  .small-text {
    font-size: 3vw;
    margin-bottom: 0.8em;
  }

  .title-desktop {
    font-size: 4vw;
  }

  .title-desktop {
    margin-left: 7.5vw;
    margin-bottom: 2vh;
  }
}

@media screen and(min-width: 840px) {
  .lines_orange {
    width: 50em;
    margin-left: 60vw;
    margin-bottom: 0vh;
  }

  .lines_orange::before {
    margin: 2% 0% 0% -20%;
  }

  .person {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 19vw;
    height: 19vw;
    clip-path: polygon(51% 0, 100% 50%, 50% 100%, 0% 50%);
  }

  .boxes {
    transform: rotate(45deg);
    width: 15vw;
    height: 15vw;
    position: absolute;
  }

  .boxes:before {
    margin: 3% 0% 0% -54%;
  }

  .background {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3vw;
    position: relative;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em;
    flex-basis: 40%;
    width: 100%;
    position: relative;
  }

  .text-box {
    margin-top: 4vw;
    margin-bottom: 5em;
  }

  .big-text {
    font-size: 2.25vw;
    margin-bottom: 0.8em;
  }

  .small-text {
    font-size: 1.6vw;
    margin-bottom: 0.8em;
  }

  .romb_right {
    position: absolute;
    margin-left: 95%;
    top: 60%;
    height: 10vw;
    width: 10vw;
  }

  .romb_left {
    position: absolute;
    top: 60%;
    margin-right: 95%;
    margin-left: 0%;
    height: 10vw;
    width: 10vw;
  }
}
</style>
