<template>
  <div id="app">
    <div v-if="burger">
      <Burger />
    </div>
    <div v-else>
      <Navbar />
    </div>
    <router-view></router-view>
    <BackToTopButton />
    <Footer />
  </div>
</template>

<script>
import Burger from "@/components/global/Mobile/Burger.vue";
import Footer from "@/components/global/Footer.vue";
import Navbar from "@/components/global/Navbar.vue";
import BackToTopButton from "@/components/global/BackToTopButton.vue";

export default {
  name: 'App',
  components: {
    Burger,
    Footer,
    Navbar,
    BackToTopButton,
  },
  computed: {
    burger() {
      return this.width <= 705;
    },
  },
  data() {
    return { width: window.innerWidth, height: window.innerHeight };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>

<style>

html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    position: relative;
    margin: 0;
    padding: 0;
}

#app {
  overflow-x: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
