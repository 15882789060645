<template>
    <div class="contact-page">
        <ContactPersons class="contact-person"/>
        <!-- <FAQ /> -->
        <ContactForm />
    </div>
</template>

<script>
import ContactPersons from "../components/contact/ContactPersons.vue";
import ContactForm from "../components/contact/ContactForm.vue";
// import FAQ from "../components/contact/FAQ.vue";

export default {
  components: {
    ContactForm,
    ContactPersons,
    // FAQ,
  },
};
</script>

<style lang="scss" scoped>

.contact-page {
  background-color: $background-color;
}

</style>
