<template>
    <div id="register-page">
        <RegisterHero />
        <Rules />
    </div>
</template>

<script>
import RegisterHero from "../components/register/RegisterHero.vue";
import Rules from "../components/register/Rules.vue";

export default {
  components: {
    RegisterHero,
    Rules,
  },
};
</script>
