<template>
  <div class="timeline pb-5">
    <div class="title">
      <h1
        class="font-montserrat mb-24"
        style="font-color: #1b3256; font-weight: bold"
      >
        Timeline
      </h1>
      <img class="photo" src="../../assets/images/timeline.jpg" />
      <div class="title-box" />
    </div>
    <div class="romb_left" />
    <div v-for="(event, index) in $t('challenges.timeline')" :key="index">
      <div
        class="boxes"
        v-bind:id="event.id"
        v-on:click="displayChallenge(index)"
      >
        <p :class="{ challenges: event.main, challenges2: !event.main }">
          {{ event.name }}
        </p>
        <p :class="{ date: event.main, date2: !event.main }">
          {{ event.date }}
        </p>
      </div>
    </div>
    <div v-for="(event, index) in $t('challenges.timeline')" :key="event.name">
      <div class="big-challenge boxes" v-on:click="displayBigChallenge(index)">
        <p class="text">{{ event.name }}</p>
        <p class="big-date">{{ event.description }}</p>
      </div>
    </div>
    <div class="romb_right" />
  </div>
</template>

<script>
export default {
  name: "Timeline",
  methods: {
    displayChallenge(id) {
      const oneChallenge = document.getElementsByClassName("boxes");
      oneChallenge[0].style.display = "none";
      oneChallenge[1].style.display = "none";
      oneChallenge[2].style.display = "none";
      oneChallenge[3].style.display = "none";
      oneChallenge[4].style.display = "none";
      const big = document.getElementsByClassName("big-challenge");
      big[id].style.display = "inline-block";
      const date = document.getElementsByClassName("big-date");
      date[id].style.display = "inline-block";
    },
    displayBigChallenge(id) {
      const big = document.getElementsByClassName("big-challenge");
      big[id].style.display = "none";
      const date = document.getElementsByClassName("big-date");
      date[id].style.display = "none";
      const oneChallenge = document.getElementsByClassName("boxes");
      oneChallenge[0].style.display = "block";
      oneChallenge[1].style.display = "block";
      oneChallenge[2].style.display = "block";
      oneChallenge[3].style.display = "block";
      oneChallenge[4].style.display = "block";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/svg/romb_left.scss";
@import "../../assets/svg/romb_right.scss";
@import "../../assets/svg/boxes.scss";

// de aici se modifica dim la pentru linii
.text {
  transform: rotate(-45deg);
  margin-left: -50%;
  margin-top: 20%;
  font-size: 3.5vw;
}

.big-challenge {
  display: none;
  color: white;
  width: 32vw;
  height: 32vw;
  background-color: #1b3256;
}

.big-date {
  transform: rotate(-45deg);
  display: none;
  width: 30vw;
  font-size: 1.3vw;
  transform: rotate(-45deg);
  margin-left: 2%;
  margin-top: 1%;
}

.challenges {
  color: black;
  width: 50px;
  height: 50px;
  transform: rotate(-45deg);
  font-size: 70%;
  text-align: center;
  padding-top: 25%;
}

.challenges2 {
  color: black;
  width: 70px;
  height: 70px;
  transform: rotate(-45deg);
  font-size: 70%;
  text-align: center;
  padding-top: 30%;
}

.date {
  color: black;
  width: 70px;
  height: 70px;
  transform: rotate(-45deg);
  font-size: 50%;
  margin-left: 35%;
  margin-top: -25%;
  text-align: center;
}

.date2 {
  color: black;
  width: 40px;
  height: 50px;
  transform: rotate(-45deg);
  font-size: 50%;
  margin-left: 50%;
  margin-top: -40%;
  text-align: center;
}

#id1 {
  transform: rotate(45deg) scale(4);
  margin-left: 30%;
}

#id2 {
  transform: rotate(45deg) scale(3);
  margin-top: -15%;
  margin-left: 75%;
}

#id3 {
  transform: rotate(45deg) scale(3);
  margin-top: 23%;
  margin-left: 60%;
}

#id4 {
  transform: rotate(45deg) scale(3);
  margin-top: -20%;
  margin-left: 75%;
}

h1 {
  text-align: left;
  top: -60%;
  margin-top: 10%;
  margin-bottom: 0%;
  margin: 0%;
  padding-left: 10%;
  padding-top: 25%;
  position: absolute;
  font-size: 400%;
}

.title {
  position: relative;
}

.title-box {
  width: 90%;
  display: block;
  text-align: left;
  height: 40%;
  border: 3px solid #f0ad30;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 7%;
  padding-top: 10%;
  padding-bottom: 10%;
  top: -10px;
  position: absolute;
  z-index: 10;
}

.photo {
  position: relative;
  right: -35%;
  top: -25%;
  margin-top: 15%;
  margin-bottom: 25%;
  width: 55%;
  height: 80%;
  z-index: 20;
}

.romb_right {
  transform: rotate(-135deg) scale(2.5);
  margin: 5vh;
  margin: 10%;
  right: -85%;
  margin-top: 10%;
}

.romb_left {
  transform: rotate(45deg) scale(2.5);
  margin: 5%;
  left: -5%;
  // margin-top: -20%;
}

.boxes {
  margin: 5vh;
  transform: rotate(45deg);
}

@media (max-width: 500px) {
  .text{
    margin-left: -60%;
  }
  .big-challenge {
    width: 60vw;
    height: 60vw;
  }
  .big-date{
    font-size: 2.5vw;
    width: 40vw;
    margin-top: 0;
  }
  h1 {
    font-size: $font_mobile_small_3;
    top: -67%;
  }
  .romb_right {
    transform: rotate(-135deg) scale(1);
    margin: 5vh;
    margin: 10%;
    right: -75%;
    margin-top: 10%;
  }
  .romb_left {
    transform: rotate(45deg) scale(1);
    margin: 0%;
    left: -5%;
  }
  #id1 {
    transform: rotate(45deg) scale(2);
    margin-left: 40%;
  }

  #id2 {
    transform: rotate(45deg) scale(1.25);
    margin-top: 30%;
    margin-left: 20%;
  }

  #id3 {
    transform: rotate(45deg) scale(1.25);
    margin-top: -15%;
    margin-left: 60%;
  }

  #id4 {
    transform: rotate(45deg) scale(1.25);
    margin-top: 10%;
    margin-left: 35%;
  }
}
</style>
