<template>
  <div class="countdown">
    <div class="card">
      <div class="text">
        <img class="bullet" src="../../assets/svg/homepageSVG.svg" />
        <p class="font-roboto text-2xl font-bold text-black">
          {{ $t("home.Countdown.header") }}
        </p>
      </div>
      <div class="container">
        <div class="clock">
          <img
            class="logo"
            src="../../assets/images/logo_eestec_olympics_10.png"
          />
          <OnlyCountdown extdate="2022-03-13T23:59:59Z" class="onlyc" />
        </div>
        <div class="if">
          <div class="if-condition text-4xl font-roboto font-bold text-black">
            if(smth smth) {
          </div>
          <a href="https://forms.gle/f6YVykGY38uQ9y1r7">
            <button class="apply text-4xl font-roboto font-bold text-yellow">
              &emsp;applyHere();
            </button>
          </a>
          <div class="if-condition text-4xl font-roboto font-bold text-black">
            } else if(questions) {
          </div>
          <button
            class="contact text-4xl font-roboto font-bold text-yellow"
            @click="toContact"
          >
            &emsp;contact();
          </button>
          <div class="if-condition text-4xl font-roboto font-bold text-black">
            }
          </div>
        </div>
      </div>
    </div>
    <div class="background" />
  </div>
</template>

<script>
import OnlyCountdown from "@/components/home/OnlyCountdown.vue";

export default {
  components: {
    OnlyCountdown,
  },
  methods: {
    toRegister() {
      this.$router.push("/register");
    },
    toContact() {
      this.$router.push("/contact");
    },
  },
};
</script>

<style lang="scss" scoped>
.countdown {
  background-color: $background-color;
  //background-image: url("../../assets/svg/Poligon-galben.svg");
  width: 100%;
  height: 100%;
  position: relative;
}

.background {
  width: 100%;
  height: 100%;
  background-color: $secondary-color-line;
  opacity: 50%;
  clip-path: polygon(50% 0%, 100% 0%, 50% 100%, 0% 100%);
  position: absolute;
  z-index: 1;
  top: 0;
}

.text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bullet {
  position: absolute;
  margin-top: 1em;
  width: 3em;
  margin-left: 1em;
}

p {
  text-align: left;
  margin-left: 3em;
  margin-top: 0.8em;
  position: absolute;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding-top: 3em;
  z-index: 99;
  position: relative;
}

.onlyc {
  z-index: 99;
  padding-bottom: 2em;
}

.card {
  z-index: 99;
  position: relative;
}

@media screen and (max-width: 499px) {
  .if {
    display: none;
  }
}

@media (min-width: 500px) {
  .bullet {
    position: absolute;
    margin-top: 3vw;
    width: 7vw;
  }

  p {
    text-align: left;
    margin-top: 3.5vw;
    margin-left: 12vw;
    position: absolute;
    font-size: 2.6vw;
  }

  .container {
    display: flex;
    flex-direction: row;
  }

  .clock {
    width: 60%;
  }

  .if {
    width: 40%;
    margin: auto;
    text-align: left;
  }

  .if-condition,
  .apply,
  .contact {
    margin-top: 3%;
    font-size: 2.5vw;
  }

  .apply:hover {
    transform: scale(1.05);
  }

  .onlyc {
    padding-bottom: 10%;
  }

  .logo {
    padding-top: 10%;
  }
}

@media (min-width: 900px) {
  p {
    margin-top: 4.5vw;
    margin-left: 11vw;
  }
}
</style>
