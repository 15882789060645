import Vue from "vue";

import VueResource from 'vue-resource';
import Vuei18n from 'vue-i18n';

import en from './locales/en.json';
import ro from './locales/ro.json';

import App from "./App.vue";
import router from "./router";
// import store from "./store";

import './assets/styles/tailwind.css'; //importing tailwind

Vue.config.productionTip = false;

Vue.use(VueResource);
//this key shouldn't be fucking public but I won't do something about it

Vue.use(Vuei18n);

const i18n = new Vuei18n({
  locale: 'ro',
  messages: {
    en,
    ro,
  },
});

new Vue({
  router,
  // store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
