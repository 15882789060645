<template>
  <div class="contactForm pt-10 pb-3">
    <div class="head">
      <h1>{{$t("contact.contact-form.title")}}</h1>
      <div class="lines_orange"/>
    </div>
    <div class="form-box">
      <div class="fix">
        <div class="label-text" >{{$t("contact.contact-form.name")}}</div>
        <input
          type="textbox"
          v-model="name"
          name="name"
          class="text-box" />
        <div class="label-text">{{$t("contact.contact-form.email")}}</div>
        <input
          type="textbox"
          v-model="email"
          name="email"
          class="text-box" />
        <div class="label-text">{{$t("contact.contact-form.message")}}</div>
        <textarea
          v-model="message"
          name="message" />
        <button
          class="text-button"
          type="submit"
          @click="sendEmail(); isHidden = !isHidden"
          value = "Send">{{$t("contact.contact-form.button")}}</button>
      </div>
      <div v-if="!isHidden" class="alert">
        <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
        {{$t("contact.contact-form.popup")}}
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import ID from '../../assets/resources/services';

export default {
  name: "ContactUs",
  data() {
    return {
      isHidden: true,
      name: null,
      email: null,
      message: null,
      ID: this.ID,
    };
  },
  methods: {
    sendEmail() {
      try {
        emailjs.send(ID.serviceID, ID.templateID, {
          name: this.name,
          email: this.email,
          message: this.message,
        }, ID.userID);
        console.log('it works!!!');
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.name = '';
      this.email = '';
      this.message = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/svg/lines_orange.scss";

@media(max-width: 480px){
  .lines_orange {
    width: 25em;
    height: 0.5em;
    background-color: $secondary-color;
    position: relative;
  }

  .lines_orange::before {
    position: absolute;
    content: "";
    background-color: $secondary-color;
    height: 100%;
    width: 100%;
    margin: 4% 10% 0% -5%;
  }
  .head {
    margin-left: 15%;
    text-align: start;
    width: 85%;
    display: grid;
    grid-template-columns: 70% 30%;
  }
  h1 {
    font-size: $font_mobile_medium_2;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    color: $primary-font-color;
  }
  .form-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    font-size: $font_mobile_small_2;
    font-family: "Roboto";
    background-color: $primary-color;
    border-radius: 1rem;
    margin: 10% auto;
    padding: 5%;
  }
  .fix {
    width: 90%;
  }
  .text-box {
    width: 100%;
    height: 5%;
    background-color: #d7dedc;
    color: $primary-color;
    padding: 5%;
    border-radius: 1em;
  }
  .label-text {
    color: #d7dedc;
    padding: 10% 0;
    text-align: left;
  }
  textarea {
    width: 100%;
    height: 10em;
    resize: none;
    border-radius: 1em;
    padding: 3%;
    background-color: #d7dedc;
    color: $primary-color;
    margin-bottom: 10%;
  }
  .text-button {
    font-size: $font_mobile_small_2;
    font-family: "Roboto";
    background-color: $secondary-color;
    color: $primary-color;
    display: block;
    border-radius: 1em;
    width: 60%;
    height: 2.5em;
    margin-top: 2em;
  }
  .alert {
    padding: 5%;
    background-color: #deefc5;
    color: black;
    transition: all .5s ease-in-out;
    width: 80%;
    border-radius: 1em;
  }
  .alert:target{
    height:3%;
  }

  .closebtn {
    color: black;
    font-weight: bold;
    float: right;
    font-size: $font_mobile_small_2;
    cursor: pointer;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: grey;
  }
}
@media (min-width: 481px){
  .lines_orange {
    width: 25em;
    height: 0.5em;
    background-color: $secondary-color;
    position: relative;
    right: -70%;
  }

  .lines_orange::before {
    position: absolute;
    content: "";
    background-color: $secondary-color;
    height: 100%;
    width: 100%;
    margin: 4% 10% 0% 25%;
  }
  .head {
    margin-left: 5%;
    text-align: start;
    width: 85%;
    display: flex;
  }
  h1 {
    font-size: 2.5vw;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    color: $primary-font-color;
  }
  .form-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    font-size: 20px;
    font-family: "Roboto";
    background-color: $primary-color;
    border-radius: 1rem;
    margin: 5% auto;
    padding: 5%;
  }
  .fix {
    width: 90%;
  }
  .text-box {
    width: 100%;
    height: 5%;
    background-color: #d7dedc;
    color: $primary-color;
    padding: 4%;
    border-radius: 0.5em;
  }
  .label-text {
    color: #d7dedc;
    padding: 6% 0;
    text-align: left;
  }
  textarea {
    width: 100%;
    height: 10em;
    resize: none;
    border-radius: 0.5em;
    padding: 3%;
    background-color: #d7dedc;
    color: $primary-color;
    margin-bottom: 5%;
  }
  .text-button {
    font-size: 20px;
    font-family: "Roboto";
    background-color: $secondary-color;
    color: $primary-color;
    display: block;
    border-radius: 0.5em;
    width: 50%;
    height: 2.5em;
    margin-top: 2em;
  }
  .alert {
    padding: 5%;
    background-color: #deefc5;
    color: black;
    transition: all .5s ease-in-out;
    width: 80%;
    border-radius: 1em;
  }
  .alert:target{
    height:3%;
  }

  .closebtn {
    color: black;
    font-weight: bold;
    float: right;
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: grey;
  }
}
</style>
