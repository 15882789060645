const contacts = [
  {
    photo: "https://i.imgur.com/LzniQGD.png",
  },
  {
    photo: "https://i.imgur.com/upbbkv9.png",
  },
];

export default contacts;
