<template>
  <div class="text-box">
    <div class="timecase">
      <div class="block text-2xl">
        <div class="case">
          <p class="digit text-2xl font-bold font-roboto">
            {{ days }}
          </p>
          <p class="text text-2xl font-bold font-roboto">
            {{ $t("home.Countdown.days") }}
          </p>
        </div>
      </div>
      <div class="block-middle text-2xl">
        <div class="case">
          <p class="digit text-2xl font-bold font-roboto">
            {{ hours }}
          </p>
          <p class="text text-2xl font-bold font-roboto">
            {{ $t("home.Countdown.hours") }}
          </p>
        </div>
      </div>
      <div class="block min text-2xl">
        <div class="case">
          <p class="digit text-2xl font-bold font-roboto">
            {{ minutes }}
          </p>
          <p class="text text-2xl font-bold font-roboto">
            {{ $t("home.Countdown.min") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["extdate"],
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      date: Math.trunc(Date.parse(this.extdate) / 1000),
    };
  },
  computed: {
    seconds() {
      return (this.date - this.now) % 60 > 0 ? (this.date - this.now) % 60 : 0;
    },
    minutes() {
      return Math.trunc((this.date - this.now) / 60) % 60 > 0
        ? Math.trunc((this.date - this.now) / 60) % 60
        : 0;
    },
    hours() {
      return Math.trunc((this.date - this.now) / 60 / 60) % 24 > 0
        ? Math.trunc((this.date - this.now) / 60 / 60) % 24
        : 0;
    },
    days() {
      return Math.trunc((this.date - this.now) / 60 / 60 / 24) - 1 > 0
        ? Math.trunc((this.date - this.now) / 60 / 60 / 24)
        : 0;
    },
  },
  mounted() {
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.case {
  display: inline-block;
}

.timecase {
  display: flex;
  justify-content: center;
  align-content: center;
}

.block,
.block-middle {
  display: flex;
  margin: 2%;
  margin-top: 3%;
  text-align: center;
}

.min {
  margin-left: -0.2rem;
}

.text {
  color: #000000;
  line-height: 1em;
  margin-bottom: -6%;
  margin-top: 6%;
  margin-left: 6%;
}

.digit {
  color: #000000;
  margin-bottom: -6%;
  margin-top: 6%;
  line-height: 1em;
}

.block-middle::after,
.block-middle::before {
  content: "";
  font-weight: bold;
  width: 2px;
  height: 80%;
  position: relative;
  top: 20%;
  background-color: #000000;
}
.block-middle::after {
  margin: 0 0.3em 0 0.5em;
}

.block-middle::before {
  margin: 0 0.3em;
}

@media (min-width: 500px) {
  .text {
    color: #000000;
    line-height: 1em;
    margin-bottom: -6%;
    margin-top: 6%;
    margin-left: 6%;
    font-size: 2.5vw;
  }

  .digit {
    color: #000000;
    margin-bottom: -6%;
    margin-top: 6%;
    line-height: 1em;
    font-size: 2.5vw;
  }
}
</style>
